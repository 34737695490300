import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

const PreviewCompatibleImage = ({ imageInfo }) => {
  const { alt = '', childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp && !!image.childImageSharp.fluid) {
    return <Img fluid={image.childImageSharp.fluid} alt={alt} />;
  }

  if (!!image && !!image.childImageSharp && !!image.childImageSharp.fixed) {
    return <Img fixed={image.childImageSharp.fixed} alt={alt} />;
  }

  if (!!childImageSharp && !!childImageSharp.fluid) {
    return <Img fluid={childImageSharp.fluid} alt={alt} />;
  }

  if (!!childImageSharp && !!childImageSharp.fixed) {
    return <Img fixed={childImageSharp.fixed} alt={alt} />;
  }

  if (!!image && typeof image === 'string')
    return <img src={image} alt={alt} />;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  }).isRequired,
};

export default PreviewCompatibleImage;
